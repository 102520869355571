import React from "react";
import Box from "@components/Box";
import "./Boxes.styles.scss";

const Boxes = ({ data = [] }) => {
  return (
    <div className="boxes">
      <div className="boxes__inner container">
        <div className="boxes__rows">
          {data?.map((item) => {
            return (
              <Box
                name={item?.fullname}
                jobtype={item?.role}
                image={item?.image}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Boxes;
