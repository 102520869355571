import React from "react";
import "./Advantage.styles.scss";

const Advantage = ({ Icon, title }) => {
  return (
    <div className="advantage">
      <Icon />
      <p>{title}</p>
    </div>
  );
};

export default Advantage;
