import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="50"
      fill="none"
      viewBox="0 0 46 50"
    >
      <path
        fill="#EB175C"
        d="M19.325 4.31a17.942 17.942 0 017.35 0l4.3-4.31 3.617 3.621-2.683 2.687a17.98 17.98 0 017.257 7.858H46v5.119h-5.29c.118.836.179 1.688.179 2.558v2.56H46v5.117h-5.111v2.56c0 .87-.061 1.721-.179 2.558H46v5.118h-6.834a17.905 17.905 0 01-6.6 7.468 17.872 17.872 0 01-19.132 0 17.905 17.905 0 01-6.6-7.468H0v-5.118h5.29c-.12-.847-.18-1.703-.179-2.559V29.52H0v-5.118h5.111v-2.559c0-.87.061-1.722.179-2.558H0v-5.119h6.834a17.98 17.98 0 017.257-7.86l-2.683-2.688L15.027.003l4.3 4.309-.002-.003zM23 9.048a12.77 12.77 0 00-9.035 3.747 12.803 12.803 0 00-3.743 9.047V32.08c0 3.394 1.346 6.648 3.743 9.048a12.77 12.77 0 0018.07 0 12.803 12.803 0 003.743-9.048V21.843c0-3.393-1.346-6.647-3.743-9.047A12.77 12.77 0 0023 9.05zM15.333 29.52h15.334v5.118H15.333V29.52zm0-10.235h15.334v5.117H15.333v-5.117z"
      ></path>
    </svg>
  );
}

export default Icon;
