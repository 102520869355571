import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="40"
      fill="none"
      viewBox="0 0 50 40"
    >
      <path
        fill="#EB175C"
        d="M6.818 4.444V28.89h36.364V4.444H6.818zM2.273 2.238C2.273 1.002 3.307 0 4.527 0h40.946c1.245 0 2.254.998 2.254 2.238v31.095H2.273V2.238zM0 35.556h50V40H0v-4.444z"
      ></path>
    </svg>
  );
}

export default Icon;
