import React from "react";
import Header from "@layouts/Header";
import Boxes from "./components/Boxes";
import DefaultSection from "@sections/DefaultSection";
import SliderSection from "@sections/SliderSection";
import Contact from "@sections/Contact";

import Advantage1 from "@components/svg/Advantages1";
import Advantage2 from "@components/svg/Advantages2";
import Advantage3 from "@components/svg/Advantages3";
import Advantage4 from "@components/svg/Advantages4";

import Advantage from "@components/Advantage";

import "./About.styles.scss";

const icons = [Advantage1, Advantage2, Advantage3, Advantage4];

const AboutTemplate = ({ data }) => {
  const header = data?.header || {};
  const firstSection = data?.firstSection || {};
  const team = data?.team || {};
  const customers = data?.customers || {};
  const contact = data?.contact || {};

  return (
    <div>
      <Header {...header} />
      <DefaultSection
        info={{
          ...firstSection,
        }}
        rightSide={
          <div className="about__advantages">
            {firstSection?.items?.map((item, key) => {
              return (
                <Advantage Icon={icons[key]} title={item?.title} />
              );
            })}
          </div>
        }
      />
      <DefaultSection
        info={{
          ...team,
        }}
        hasBackground
        rightSide={<Boxes data={team?.people} />}
        isVertical
        hasIcons={false}
      />
      <SliderSection {...customers} />
      <Contact
        info={{
          ...contact,
          isWhite: true,
        }}
      />
    </div>
  );
};

export default AboutTemplate;
