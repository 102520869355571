import React from "react";
import Image from "@components/Image";

import "./Box.styles.scss";

const Box = ({ name, jobtype, image }) => {
  return (
    <div className="box">
      <div className="box__img">
        <Image data={image} />
      </div>
      <p className="box__name">{name}</p>
      <span className="box__job">{jobtype}</span>
    </div>
  );
};

export default Box;
