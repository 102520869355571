import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="54"
      fill="none"
      viewBox="0 0 46 54"
    >
      <path
        fill="#EB175C"
        d="M8.643 12.775A22.814 22.814 0 0123 7.715c5.43 0 10.422 1.892 14.357 5.06L41.07 9.04l3.614 3.636-3.713 3.737a23.221 23.221 0 014.883 11.839c.487 4.33-.25 8.711-2.128 12.638a23.095 23.095 0 01-8.485 9.561A22.894 22.894 0 0123 54c-4.331 0-8.574-1.23-12.24-3.55a23.095 23.095 0 01-8.486-9.56A23.271 23.271 0 01.146 28.25a23.221 23.221 0 014.883-11.84l-3.713-3.733L4.93 9.042l3.713 3.736v-.003zM23 48.86c2.35 0 4.675-.465 6.846-1.37a17.881 17.881 0 005.803-3.902 18.007 18.007 0 003.878-5.84 18.099 18.099 0 000-13.777 18.007 18.007 0 00-3.878-5.84 17.88 17.88 0 00-5.803-3.902A17.791 17.791 0 0023 12.858a17.833 17.833 0 00-12.65 5.272A18.058 18.058 0 005.112 30.86c0 4.774 1.885 9.352 5.24 12.728A17.833 17.833 0 0023 48.86zm2.556-20.572h7.666L20.444 45.002V33.43h-7.666l12.778-16.728v11.585zM12.778 0h20.444v5.143H12.778V0z"
      ></path>
    </svg>
  );
}

export default Icon;
