import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="42"
      fill="none"
      viewBox="0 0 48 42"
    >
      <path
        fill="#EB175C"
        d="M2.4 0h43.2c.636 0 1.247.246 1.697.683.45.438.703 1.031.703 1.65v37.334c0 .618-.253 1.212-.703 1.65-.45.437-1.06.683-1.697.683H2.4a2.435 2.435 0 01-1.697-.683A2.301 2.301 0 010 39.667V2.333A2.3 2.3 0 01.703.683 2.435 2.435 0 012.4 0zm2.4 4.667v32.666h38.4V4.667H4.8zM24 28h14.4v4.667H24V28zm-8-7l-6.789-6.599 3.396-3.301L22.788 21l-10.18 9.9L9.21 27.6 16.001 21z"
      ></path>
    </svg>
  );
}

export default Icon;
